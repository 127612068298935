<template>
  <div class="user-relationships-wrap card-padding">
    <div class="card-title flex-b-c">
      <div>用户关系管理</div>
    </div>
    <div class="tabPane-wrap flex-c">
      <div class="tabPane-list">
        <span
          v-for="(item, index) in tabPaneList"
          :key="index"
          :class="{ 'active-tab': item.value === activeId }"
          @click="tabPaneClick(item.value)"
        >{{ item.label }}</span>
      </div>
      <div class="search-wrap">
        <!-- @input="searchHandle" -->
        <el-input v-model="keyWord" placeholder="输入搜索关键词">
          <template #suffix>
            <el-icon class="el-input__icon cursor" @click="searchHandle"><Search /></el-icon>
          </template>
        </el-input>
      </div>
    </div>
    <div class="task-nav-wrap">
      <MyFollow
        v-show="+activeId === 1"
        ref="myFollowRef"
        :user-info="userInfo"
        :user-type="userType"
        :key-word="keyWord"
      />
      <FollowMe
        v-show="+activeId === 2"
        ref="followMeRef"
        :user-info="userInfo"
        :user-type="userType"
        :key-word="keyWord"
      />
      <MyColleague
        v-show="+activeId === 3"
        ref="myColleague"
        :user-info="userInfo"
        :user-type="userType"
        :key-word="keyWord"
      />
    </div>
  </div>
</template>

<script>
import MyFollow from './myFollow';
import FollowMe from './followMe';
import MyColleague from './myColleague';
export default {
  components: { MyFollow, FollowMe, MyColleague },
  props: {
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userTypeTabMap: {
        1: [
          {
            label: '关注我的',
            value: '2',
          },
          {
            label: '我的同事',
            value: '3',
          },
        ],
        2: [
          {
            label: '我的关注',
            value: '1',
          },
          {
            label: '关注我的',
            value: '2',
          },
          {
            label: '我的同事',
            value: '3',
          },
        ],
      },
      tabPaneList: [],
      activeId: 1,
      keyWord: '',
    };
  },
  watch: {
    userType: {
      handler(newVal) {
        this.tabPaneList = this.userTypeTabMap[newVal];
        this.activeId = this.userType === 1 ? '2' : '1';
        // this.$nextTick(() => {
        //   this.tabPaneClick(this.activeId);
        // });
      },
      immediate: true,
    },
  },
  methods: {
    tabPaneClick(value) {
      this.activeId = value;
      if (+this.activeId === 1 && this.$refs.myFollowRef) {
        this.$refs.myFollowRef.getList();
      } else if (+this.activeId === 2 && this.$refs.followMeRef) {
        this.$refs.followMeRef.getList();
      } else if (+this.activeId === 3 && this.$refs.myColleague) {
        this.$refs.myColleague.getList();
      }
    },
    // 搜索
    searchHandle() {
      this.tabPaneClick(this.activeId);
    },
  },
};

</script>
<style lang="scss" scoped>
.user-relationships-wrap {
  .tabPane-wrap {
    width: 100%;
    margin-bottom: 10px;
    .tabPane-list {
      display: flex;
      border: 1px solid rgba(226, 227, 228, 1);
      margin-right: 10px;
      span {
        display: block;
        padding: 8px 15px;
        // border-radius: 4px;
        cursor: pointer;
        border-right: 1px solid rgba(226, 227, 228, 1);
      }
      .active-tab {
        background: #e2e3e4;
      }
      > :last-child {
        border-right: none;
      }
    }
    .search-wrap {
      width: 200px;
    }
  }
}
</style>
