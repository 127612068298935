<template>
  <el-drawer
    :visible="drawerVisible"
    title="实名认证申请"
    class="g-drawer"
    :wrapper-closable="false"
    size="600px"
    destroy-on-close
    @open="open"
    @close="close"
  >
    <div class="g-drawer-c">
      <el-form
        ref="formRef"
        :model="drawerForm"
        :rules="drawerRules"
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="身份证正反面" prop="idCodePic">
          <ImageUpload
            :model-value.sync="drawerForm.idCodePic"
            placeholder="身份证正反面"
            :mode="'edit'"
            :limit="2"
          />
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCode">
          <el-input v-model="drawerForm.idCode" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="身份证有效期止" prop="idCodeExpire">
          <el-date-picker
            v-model="drawerForm.idCodeExpire"
            value-format="timestamp"
            type="date"
            placeholder="请选择"
          />
        </el-form-item>
        <el-form-item label="手机号码" prop="userPhone">
          <div>{{ drawerForm.userPhone }}</div>
          <el-button class="ml10" type="primary" :disabled="!!waitTime" @click="receiveSmsCode">{{
            waitTime ? `重新获取${waitTime}s` : '发送验证码'
          }}</el-button>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="drawerForm.code" maxlength="11" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div class="g-btn-c">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">提交审核</el-button>
    </div>
  </el-drawer>
</template>

<script>
import { goodsUserDetailCheck, transUserDetailCheck } from '@/api/userManageMent';
import { sendCode as userMessageSend } from '@/api/user';
import { validateID } from '@/utils/validateRule';
export default {
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      waitTime: undefined,
      drawerForm: {
        idCodePic: '',
        idCode: '',
        code: '',
        userPhone: '',
      },
      drawerRules: {
        idCodePic: [{ required: true, message: '请上传身份证', trigger: 'blur' }],
        idCode: [
          { required: true, message: '请输入身份证号码', trigger: 'change' },
          { validator: validateID, trigger: 'change' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'change' }],
      },
    };
  },
  methods: {
    open() {
      if (this.drawerType === 'edit') {
        const newUserInfo = JSON.parse(JSON.stringify(this.userInfo));
        this.drawerForm = {
          idCodePic: newUserInfo.idCodePic || '',
          idCode: newUserInfo.idCode || '',
          userPhone: newUserInfo.userPhone,
          userName: newUserInfo.userName,
          idCodeExpire: Number(this.userInfo?.idCodeExpire) || '',
        };
      } else {
        this.drawerForm.userPhone = this.userInfo.userPhone;
      }
    },
    close() {
      this.drawerForm = {
        idCodePic: '',
        idCode: '',
        code: '',
        idCodeExpire: '',
        userPhone: '',
      };
      this.waitTime = undefined;
      this.$refs.formRef.resetFields();
      this.$emit('update:drawerVisible', false);
    },
    // 发送短信验证码
    sendSmsCode() {
      if (!this.userInfo.userPhone) {
        this.$baseMessage('手机号不能为空', 'error');
        return;
      }
      const data = {
        phone: this.userInfo.userPhone,
        userType: +this.userType,
      };
      userMessageSend(data)
        .then((res) => {
          if (res) {
            this.smsCodeInterval();
          }
        })
        .catch((err) => err);
    },
    // 倒计时逻辑
    smsCodeInterval() {
      this.waitTime = 60;
      const that = this;
      const timer = setInterval(function() {
        if (that.waitTime > 0) {
          that.waitTime--;
        } else {
          clearInterval(timer);
        }
      }, 1000);
    },
    // 接收短信验证码
    receiveSmsCode() {
      if (this.waitTime) return;
      this.sendSmsCode();
    },
    submit() {
      console.log(this.drawerForm, 'this.drawerForm');
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            const newDrawerForm = JSON.parse(JSON.stringify(this.drawerForm));
            const addParams = {
              ...newDrawerForm,
              userId: this.userInfo.userId,
            };
            // const editParams = {
            //   ...newDrawerForm,
            //   var2: props.userInfo.userId.toString()
            // }
            const params = addParams;
            const apiMap = {
              1: goodsUserDetailCheck,
              2: transUserDetailCheck,
            };
            const res = await apiMap[this.userType](params);
            if (res) {
              this.$baseMessage('实名成功', 'success');
              this.$emit('drawerSubmit');
              this.close();
            } else {
              this.$baseMessage('实名失败', 'error');
            }
          } catch (error) {
            console.error('失败:', error);
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
