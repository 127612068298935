
/*
1.provinceAndCityData是省市二级联动数据（不带“全部”选项）
2.regionData是省市区三级联动数据（不带“全部”选项）
3.provinceAndCityDataPlus是省市区三级联动数据（带“全部”选项）
4regionDataPlus是省市区三级联动数据（带“全部”选项）
5“全部"选项绑定的value是空字符串”"
6CodeToText是个大对象，属性是区域码，属性值是汉字 用法例如：CodeToText[‘110000’]输出北京市
7extToCode是个大对象，属性是汉字，属性值是区域码
用法例如：TextToCode[‘北京市’].code输出110000,TextToCode[‘北京市’][‘市辖区’].code输出110100,TextToCode[‘北京市’][‘市辖区’][‘朝阳区’].code输出110105*/
// import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, codeToText, TextToCode } from 'element-china-area-data';
import { provinceAndCityData, regionData, codeToText, pcTextArr, pcaTextArr } from 'element-china-area-data';
const areaData = { provinceAndCityData, regionData, codeToText, pcTextArr, pcaTextArr };
// 需要填补缺省数据 台湾省、香港、澳门；
regionData[31] = { 'value': '710000', 'label': '台湾省', 'children': [
  { 'label': '台北', 'value': '710100' },
  { 'label': '高雄', 'value': '710200' },
  { 'label': '基隆', 'value': '710300' },
  { 'label': '台中', 'value': '710400' },
  { 'label': '台南', 'value': '710500' },
  { 'label': '新竹', 'value': '710600' },
  { 'label': '嘉义', 'value': '710700' },
  { 'label': '宜兰', 'value': '710800' },
  { 'label': '桃园', 'value': '710900' },
  { 'label': '苗栗', 'value': '711000' },
  { 'label': '彰化', 'value': '711100' },
  { 'label': '南投', 'value': '711200' },
  { 'label': '云林', 'value': '711300' },
  { 'label': '屏东', 'value': '711400' },
  { 'label': '台东', 'value': '711500' },
  { 'label': '花莲', 'value': '711600' },
  { 'label': '澎湖', 'value': '711700' }],
};
regionData[32] = { 'value': '810000', 'label': '香港', 'children': [{ 'value': '810100', 'label': '香港' }] };
regionData[33] = { 'value': '820000', 'label': '澳门', 'children': [{ 'value': '820100', 'label': '澳门' }] };
const newAddCodeToText = {
  710000: '台湾省',
  710100: '台北',
  710200: '高雄',
  710300: '基隆',
  710400: '台中',
  710500: '台南',
  710600: '新竹',
  710700: '嘉义',
  710800: '宜兰',
  710900: '桃园',
  711000: '苗栗',
  711100: '彰化',
  711200: '南投',
  711300: '云林',
  711400: '屏东',
  711500: '台东',
  711600: '花莲',
  711700: '澎湖',
  810000: '香港',
  810100: '香港',
  820000: '澳门',
  820100: '澳门',
};
const CodeToText = { ...codeToText, ...newAddCodeToText };
pcaTextArr[31] = {
  label: '台湾省',
  value: '台湾省',
  children: [
    {
      label: '台北',
      value: '台北',
    },
    {
      label: '高雄',
      value: '高雄',
    },
    {
      label: '基隆',
      value: '基隆',
    },
    {
      label: '台中',
      value: '台中',
    },
    {
      label: '台南',
      value: '台南',
    },
    {
      label: '新竹',
      value: '新竹',
    },
    {
      label: '嘉义',
      value: '嘉义',
    },
    {
      label: '宜兰',
      value: '宜兰',
    },
    {
      label: '桃园',
      value: '桃园',
    },
    {
      label: '苗栗',
      value: '苗栗',
    },
    {
      label: '彰化',
      value: '彰化',
    },
    {
      label: '南投',
      value: '南投',
    },
    {
      label: '云林',
      value: '云林',
    },
    {
      label: '屏东',
      value: '屏东',
    },
    {
      label: '台东',
      value: '台东',
    },
    {
      label: '花莲',
      value: '花莲',
    },
    {
      label: '澎湖',
      value: '澎湖',
    },
  ],
};
pcaTextArr[32] = {
  label: '香港',
  value: '香港',
  children: [
    { label: '香港',
      value: '香港' },
  ],
};
pcaTextArr[33] = {
  label: '澳门',
  value: '澳门',
  children: [
    { label: '澳门',
      value: '澳门' },
  ],
};
export default { regionData, codeToText, areaData, CodeToText, pcTextArr, pcaTextArr };
