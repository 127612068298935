<template>
  <div class="search-table-wrap">
    <Table
      :loading="loading"
      :table-cols="tableCols"
      :table-data="tableData"
      :pagination="pagination"
      @sizeChange="sizeChange"
      @pageChange="pageChange"
    >
      <template v-slot="{ col }">
        <div v-if="col.prop === 'ext2'">
          {{ userType === 1 ? '货主端' : '承运商端' }}
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { goodsUserWorkerList, transUserWorkerList } from '@/api/userManageMent';
export default {
  props: {
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    keyWord: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      tableCols: [
        { label: '姓名', prop: 'userName' },
        {
          label: '默认企业名称',
          prop: 'titleName',
        },
        { label: '手机号码', prop: 'userPhone' },
        { label: '关注时间', prop: 'createTime', type: 'filterTime', width: '160' },
        { label: '用户端', prop: 'ext2', type: 'customize' },
        {
          label: '资质类型',
          prop: 'titleType',
          type: 'transition',
          rulesObj: this.$CONSTANT.qualificationTypeMap,
        },
        { label: '关联客服', prop: 'customerDesc' },
        {
          label: '实名认证',
          prop: 'userPassFlag',
          type: 'transition',
          rulesObj: { 1: '已实名', 0: '未实名' },
          // 3: '审核中', 4: '认证失败'
        },
        {
          label: '资质数',
          prop: 'qualificationTotal',
        },
        {
          label: '监管权限',
          prop: 'userPassFlag',
          type: 'transition',
          rulesObj: { 1: '开启', 0: '未开启' },
        },
        {
          label: '操作',
          type: 'operation',
          fixed: 'right',
          btns: [
            {
              label: '详情',
              evt: (data) => {
                this.toDetail(data);
              },
            },
          ],
        },
      ],
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0,
      },

    };
  },
  methods: {
    async getList() {
      this.loading = true;
      const params = {
        current: this.pagination.page,
        size: this.pagination.size,
        var2: this.userInfo.userId,
        var: this.keyWord,
      };
      try {
        const apiMap = {
          1: goodsUserWorkerList,
          2: transUserWorkerList,
        };
        const res = await apiMap[this.userType](params);
        let records;
        if (res && res.records.length) {
          records = res.records.map((item) => ({
            ...item,
            customerDesc: item.customerUserRelVOList
              ? item.customerUserRelVOList.map((obj) => `${obj.name || '-'}`).join('，')
              : '-',
          }));
        }
        this.tableData = records || [];
        this.pagination = {
          page: res?.current || 1,
          size: res?.size || 10,
          total: res?.total || 0,
        };
      } catch (error) {
        console.error('获取列表数据失败:', error);
      } finally {
        this.loading = false;
      }
    },
    sizeChange(val) {
      this.pagination.page = 1;
      this.pagination.size = val;
      this.getList();
    },
    pageChange(val) {
      this.pagination.page = val;
      this.getList();
    },
    toDetail(data) {
      const page = this.$router.resolve({
        path: '/user/personal/detail',
        query: {
          id: data.userId,
          type: data.ext2,
        },
      });
      window.open(page.href, '_blank'); // 打开新的页面
    },
  },
};
</script>

<style lang="scss" scoped>
.search-table-wrap {
  :deep(.g-table-c) {
    padding: 0px 0;
  }
}
</style>
