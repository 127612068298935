<template>
  <div class="detail-wrap">
    <UserInfo
      :user-type="userType"
      :user-info="detailInfo"
      :no-action="true"
      @userInfoDialogSubmit="getDetail"
    />
    <RealNameAuthInfo
      :user-type="userType"
      :user-info="detailInfo"
      @userInfoDialogSubmit="getDetail"
    />
    <BusinessQualifications :user-type="userType" :user-info="detailInfo" />
    <UserRelationships :user-type="userType" :user-info="detailInfo" />
  </div>
</template>

<script>
import UserInfo from './components/detail/userInfo';
import RealNameAuthInfo from './components/detail/realNameAuthInfo';
import BusinessQualifications from './components/detail/businessQualifications';
import UserRelationships from './components/detail/userRelationships';
import { goodsUserDetail, transUserBaseInfo } from '@/api/userManageMent';
export default {
  name: 'Detail',
  // RealNameAuthInfo, BusinessQualifications, UserRelationships
  components: { UserInfo, RealNameAuthInfo, BusinessQualifications, UserRelationships },
  data() {
    return {
      userType: 1,
      detailId: 0,
      detailInfo: {},
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      try {
        this.detailId = +this.$route.query.id;
        this.userType = +this.$route.query.type;
        const params = {
          userId: this.detailId,
        };
        const apiMap = {
          1: goodsUserDetail,
          2: transUserBaseInfo,
        };
        const res = await apiMap[this.userType](params);
        this.detailInfo = res || { userId: this.detailId };
      } catch (error) {
        console.error('获取列表数据失败:', error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail-wrap {
  font-size: 14px;
  .card-padding {
    margin-bottom: 10px;
  }
  ::v-deep .card-content{
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
  }
  ::v-deep .text-info-item {
    width: 33%;
    line-height: 36px;
    display: flex;
    align-items: center !important;
  }
  ::v-deep .label {
    width: 84px;
    text-align: right;
    color: #606266;
    margin-right: 25px;
  }
  ::v-deep .value{
    color: rgba(0, 0, 0, 0.9);
  }
  ::v-deep .action{
    cursor: pointer;
    margin-left: 10px;
    width: 30px;
    flex: 1;
  }
  ::v-deep .status-btn {
    cursor: pointer;
    height: 26px;
    width: 64px;
    line-height: 26px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    margin-left: 5px;
  }
  ::v-deep.red-btn-text {
    background-color: rgba(244, 60, 50, 0.25);
    color: rgba(244, 60, 50, 1);
  }
  ::v-deep.grey-btn-text {
    background-color: #d7d5d5;
  }
  ::v-deep.green-btn-text {
    background-color: rgba(16, 192, 96, 0.15);
    color: rgba(16, 192, 96, 1);
  }
}
</style>
