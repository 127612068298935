<template>
  <el-dialog
    :visible="dialogVisible"
    title="修改手机号码"
    width="450px"
    append-to-body
    @open="open"
    @close="close"
  >
    <div>
      <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        label-position="right"
        label-width="110px"
      >
        <el-form-item label="当前手机号" prop="userPhone">
          {{ form.userPhone }}
          <!-- <el-input v-model="form.userPhone" placeholder="请输入" clearable disabled /> -->
        </el-form-item>
        <el-form-item label="新的手机号" prop="newUserPhone">
          <el-input v-model="form.newUserPhone" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确认修改</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { validateTel } from '@/utils/validateRule';
import { goodsUserPhoneUpdate, transUserPhoneUpdate } from '@/api/userManageMent';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    currentData: {
      type: Object,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      form: {
        userPhone: '',
        newUserPhone: '',
      },
      rules: {
        newUserPhone: [
          { required: true, message: '请输入新手机号', trigger: 'change' },
          { validator: validateTel, trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    open() {
      this.form = JSON.parse(JSON.stringify(this.currentData));
    },
    close() {
      this.form = {
        userPhone: '',
        newUserPhone: '',
      };
      this.$refs.formRef.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            const params = {

              phone: this.form.userPhone,
              newPhone: this.form.newUserPhone,
              userId: this.currentData.userId,
            };
            const apiMap = {
              1: goodsUserPhoneUpdate,
              2: transUserPhoneUpdate,
            };
            const res = await apiMap[this.userType](params);
            if (res) {
              this.$baseMessage('修改成功', 'success');
              this.$emit('dialogSubmit');
              this.close();
            } else {
              this.$baseMessage('修改失败', 'error');
            }
          } catch (error) {
            console.error('失败:', error);
          }
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped></style>
