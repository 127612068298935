<template>
  <div class="business-quality-wrap card-padding">
    <div class="card-title flex-b-c">
      <div>相关业务资质</div>
      <el-button type="primary" @click="openDialog('add')">新增资质</el-button>
    </div>
    <template v-if="showQualityList.length">
      <div v-for="item in showQualityList" :key="item.id" class="quality-list">
        <div v-if="item.type !== '13'" class="card-content">
          <div class="text-info-item">
            <div class="label">企业简称</div>
            <div class="value">{{ item.simpleName || '-' }}</div>
          </div>
          <div class="text-info-item">
            <div class="label">企业名称</div>
            <div class="value">{{ item.name || '-' }}</div>
            <div v-if="item.state" :class="`${stateTypeColorMap[item.state]} status-btn`">
              {{ stateTypeMap[item.state] }}
            </div>
          </div>
          <div class="text-info-item">
            <div class="label">社会信用编码</div>
            <div class="value">{{ item.extMap ? item.extMap.companyCode || '-' : '-' }}</div>
          </div>
          <div class="action-status-wrap">
            <div class="action">
              <span class="fw_bold">{{ userInfo.titleId === item.id ? '默认' : '' }}</span>
            </div>
            <div v-if="+item.isDel !== 1" class="cursor">
              <i class="iconfont icon-edit-line" @click="openDialog('edit', item)" />
              <!-- ①未认证1、审核失败的业务资质可删除 -->
              <i
                v-if="+item.state === 1 || +item.state === 3"
                class="iconfont icon-delete-bin-line red-c ml10"
                @click="deleteClick(item)"
              />
            </div>
          </div>
          <div class="text-info-item">
            <div class="label">资质类型</div>
            <div class="value">{{ $CONSTANT.qualificationTypeMap[item.type] || '-' }}</div>
          </div>
          <div class="text-info-item">
            <div class="label">联系电话</div>
            <div class="value">{{ item.phone || '-' }}</div>
          </div>
          <div class="text-info-other-item text-info-item-address">
            <div class="label">联系地址</div>
            <div class="value">{{ item.addrRegionDesc || '-' }}{{ item.addrDetail || '-' }}</div>
          </div>
        </div>
        <div v-else class="card-content">
          <div class="text-info-item">
            <div class="label">企业简称</div>
            <div class="value">{{ item.simpleName || '-' }}</div>
          </div>
          <div class="text-info-item">
            <div class="label">归属企业名称</div>
            <div class="value">{{ item.extMap?item.extMap.belongCompanyName || '-':'-' }}</div>
            <div v-if="item.state" :class="`${stateTypeColorMap[item.state]} status-btn`">
              {{ stateTypeMap[item.state] }}
            </div>
          </div>
          <div class="text-info-item">
            <div class="label">车牌号</div>
            <div class="value">{{ item.truckNo || '-' }}</div>
          </div>
          <div class="action-status-wrap">
            <div class="action">
              <span class="fw_bold">{{ userInfo.titleId === item.id ? '默认' : '' }}</span>
            </div>
            <div v-if="+item.isDel !== 1" class="cursor">
              <i class="iconfont icon-edit-line" @click="openDialog('edit', item)" />
              <!-- ①未认证1、审核失败的业务资质可删除
              1未认证 2认证中  3已驳回 4已认证 -->
              <i
                v-if="+item.state === 1 || +item.state === 3"
                class="iconfont icon-delete-bin-line red-c ml10"
                @click="deleteClick(item)"
              />
            </div>
          </div>
          <div class="text-info-item">
            <div class="label">资质类型</div>
            <div class="value">{{ $CONSTANT.qualificationTypeMap[item.type] || '-' }}</div>
          </div>
        </div>
        <div v-if="item.type === '1' || item.type === '11'" class="card-content-papers">
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.companyExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">营业执照</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload :model-value="item.extMap.companyPic" mode="preview" />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.weihuaWayLicExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">道路运输许可证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.weihuaWayLicPic"
                  mode="preview"
                />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.weihuaBusinessLicExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">危化品经营许可证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.weihuaBusinessLicPic"
                  mode="preview"
                />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">资质来源</div>
              <div class="value">{{ +item.ext1 === 1 ? '用户自建' : '协同资质' }}</div>
            </div>
            <div class="flex-row">
              <div>
                <div class="label">资质创建时间</div>
                <div class="value">{{ item.createTime|parseTime("YYYY-MM-DD") }}</div>
              </div>
              <div v-if="+item.isDel === 1">
                <div class="label">删除状态</div>
                <div class="status-time">
                  <div class="status-btn red-btn-text">{{ +item.isDel === 1 ? '已删除' : '' }}</div>
                  <div class="value delete-time">
                    {{ item.updateTime|parseTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === '12'" class="card-content-papers">
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.companyExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">营业执照</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload :model-value="item.extMap.companyPic" mode="preview" />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">资质来源</div>
              <div class="value">{{ +item.ext1 === 1 ? '用户自建' : '协同资质' }}</div>
            </div>
            <div class="flex-row">
              <div>
                <div class="label">资质创建时间</div>
                <div class="value">{{ item.createTime|parseTime("YYYY-MM-DD") }}</div>
              </div>
              <div v-if="+item.isDel === 1">
                <div class="label">删除状态</div>
                <div class="status-time">
                  <div class="status-btn red-btn-text">{{ +item.isDel === 1 ? '已删除' : '' }}</div>
                  <div class="value delete-time">
                    {{ item.updateTime|parseTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.type === '13'" class="card-content-papers">
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.weihuaBusinessLicExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">危化品从业资格证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.weihuaBusinessLicPic"
                  mode="preview"
                />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.truckLicEffectiveTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">车辆行驶证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.truckLicPic"
                  mode="preview"
                />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.weihuaWayLicExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">道路运输证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.weihuaWayLicPic"
                  mode="preview"
                />
              </div>
            </div>
          </div>
          <div class="text-info-item flexc">
            <div class="flex">
              <div class="label">资质来源</div>
              <div class="value">{{ +item.ext1 === 1 ? '用户自建' : '协同资质' }}</div>
            </div>
            <div class="flex-row">
              <div>
                <div class="label">资质创建时间</div>
                <div class="value">{{ item.createTime|parseTime("YYYY-MM-DD") }}</div>
              </div>
              <div v-if="+item.isDel === 1">
                <div class="label">删除状态</div>
                <div class="status-time">
                  <div class="status-btn red-btn-text">{{ +item.isDel === 1 ? '已删除' : '' }}</div>
                  <div class="value delete-time">
                    {{ item.updateTime|parseTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-info-other-item flexc">
            <div class="flex">
              <div class="label">有效期止</div>
              <div class="value">
                {{ item.extMap ? parseTime(item.extMap.driverLicExpireTime, "YYYY-MM-DD") : '-' }}
              </div>
            </div>
            <div class="flex">
              <div class="label">驾驶证</div>
              <div v-if="item.extMap" class="value">
                <ImageUpload
                  :model-value="item.extMap.driverLicPic"
                  mode="preview"
                  :limit="2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="qualityList && qualityList.length > 1" class="action-wrap main-c">
        <i
          :class="`iconfont ${showMore ? 'icon-arrow-down' : 'icon-arrow-drop-right-line'} cursor`"
        />
        <span class="cursor" @click="showMore = !showMore">{{
          showMore ? '展开更多' : '收起'
        }}</span>
      </div>
    </template>
    <div v-else class="no-data">暂无数据</div>
    <QualityDrawer
      :drawer-visible.sync="drawerVisible"
      :drawer-type="drawerType"
      :drawer-id="id"
      :user-type="userType"
      :user-info="userInfo"
      :current-data="currentData"
      @drawerSubmit="drawerSubmit"
    />
  </div>
</template>

<script>
import QualityDrawer from '../drawer/qualityDrawer';
import {
  goodsUserQualificationList,
  goodsUserQualificationDelete,
  transportUserQualificationList,
  transportUserQualificationDelete,
} from '@/api/userManageMent';
import { parseTime } from '@/utils';
export default {
  components: { QualityDrawer },
  filters: { parseTime },
  props: {
    // 详情类型 add 新增 edit编辑 detail详情
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      parseTime,
      stateTypeMap: {
        1: '未认证',
        2: '认证中',
        3: '已驳回',
        4: '已认证',
      },
      stateTypeColorMap: {
        1: 'grey-btn-text',
        2: 'grey-btn-text',
        3: 'red-btn-text',
        4: 'green-btn-text',
      },
      qualityList: [],
      showMore: true,
      drawerVisible: false,
      drawerType: 'add',
      currentData: {},
      id: null,
    };
  },

  computed: {
    showQualityList() {
      if (this.qualityList && this.qualityList.length) {
        return this.showMore ? this.qualityList.slice(0, 1) : this.qualityList;
      } else {
        return [];
      }
    },
  },

  watch: {
    userInfo: {
      handler: function(val) {
        if (val && val.userId) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getList() {
      try {
        const params = {
          var2: this.userInfo.userId,
        };
        const res =
      +this.userType === 1
        ? await goodsUserQualificationList(params)
        : await transportUserQualificationList(params);
        let newRes;
        const reg1 = new RegExp(',', 'g');
        if (res) {
          newRes = res.map((item) => {
            return {
              ...item,
              addrRegionDesc: item.addrRegion ? item.addrRegion.replace(reg1, '') : '',
            };
          });
        }
        this.qualityList = newRes || [];
      } catch (error) {
        console.log(error);
      }
    },
    // 打开侧面抽屉 进行新增/编辑/查看操作
    openDialog(type, data) {
      this.id = data?.id || null;
      this.currentData = data;
      this.drawerType = type;
      this.drawerVisible = true;
    },
    // 删除资质 未认证、审核失败的业务资质可删除
    async deleteClick(data) {
      try {
        const params = {
          var2: this.userInfo.userId,
          id: data?.id || null,
        };
        const res =
      +this.userType === 1
        ? await goodsUserQualificationDelete(params)
        : await transportUserQualificationDelete(params);
        if (res) {
          this.$baseMessage('删除成功', 'success');
          this.getList();
        } else {
          this.$baseMessage('删除失败', 'error');
        }
      } catch (error) {
        console.log(error);
      }
    },
    drawerSubmit() {
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.business-quality-wrap {
  .quality-list {
    border-radius: 8px;
    background-color: rgba(247, 247, 247, 0.49);
    margin-bottom: 10px;
    position: relative;
    .action-status-wrap{
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .text-info-item {
    width: 25% !important;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }
  .text-info-other-item{
    display: flex;
    align-items: flex-start;
    line-height: 36px;
  }
  .text-info-item-address{
    width: 75%;
  }
  .text-info-item-driver{
    width: 50%;
  }
  .flex-row {
    display: flex;
    flex-direction: column;
    > div {
      width: 100%;
      display: flex;
      align-items: center;
      .status-time {
        position: relative;
        .delete-time {
          position: absolute;
          margin-bottom: -30px;
          width: 120px;
        }
      }
    }
  }
  .card-content {
    .label {
      width: 90px !important;
      margin-right: 10px !important;
    }
  }
  .card-content-papers {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #f3f4f4;
    padding: 20px 0;
    .text-info-item {
      align-items: flex-start !important;
    }
    .label {
      width: 112px !important;
      margin-right: 10px !important;
    }
  }
  .action-wrap {
    text-align: center;
    span {
      margin-left: 5px;
    }
  }
  .no-data {
    text-align: center;
    padding: 20px 0;
  }
}
@media (max-width: 1460px) {
  .business-quality-wrap {
    .text-info-item {
      width: 33% !important;
    }
    .text-info-item-address{
      width: 100%;
    }
  }
}
@media (max-width: 1260px) {
  .business-quality-wrap {
    .text-info-item {
      width: 50% !important;
    }
  }
}
@media (max-width: 900px) {
  .business-quality-wrap {
    .text-info-item {
      width: 100% !important;
    }
  }
}
</style>
