<template>
  <div class="user-info-wrap card-padding">
    <div class="card-title">用户信息</div>
    <div class="card-content">
      <div class="text-info-item">
        <div class="label">用户ID</div>
        <div class="value">{{ userInfo.userId || '-' }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">用户端</div>
        <div class="value">{{ userTypeMap[userType] || '-' }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">注册时间</div>
        <div class="value">{{ userInfo.createTime | parseTime }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">手机号码</div>
        <div class="value phone-value">{{ userInfo.userPhone || '-' }}</div>
        <div v-if="!noAction" class="main-c action" @click="editPhoneDialogVisible = true">修改</div>
      </div>
      <div class="text-info-item">
        <div class="label">密码</div>
        <div class="value">*******</div>
        <div v-if="!noAction" class="main-c action" @click="resetPassword">重置</div>
      </div>
      <div class="text-info-item">
        <div class="label">账号状态</div>
        <!-- 此版本默认启用，修改状态暂不实现 -->
        <!-- <el-switch
          v-model="userInfo.state"
          class="switchStyle"
          inline-prompt
          :active-value="1"
          active-color="#00E266"
          inactive-color="#C0C4CC"
          :inactive-value="0"
          :disabled="true"
        /> -->
        <!--:before-change="beforeShowChangeManage.bind(this)"
          active-text="启用"
          inactive-text="禁用" :before-change="beforeChange" accountState-->
        <!-- <el-switch v-model="accountState" active-color="#00E266" :disabled="true" /> -->
        <div class="value">{{ accountStateMap[+userInfo.state]|| '-' }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">来源</div>
        <div class="value">{{ sourceTypeMap[userInfo.ext1] || '-' }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">昵称</div>
        <div class="value">{{ userInfo.userNickName || '-' }}</div>
      </div>
      <div class="text-info-item">
        <div class="label">关联客服</div>
        <div class="value customer-value ellipsis">
          <span v-for="(item, index) in userInfo.customerUserRelVOList" :key="index" class="mr10 ellipsis">
            <el-tooltip effect="dark" :content="`${roleCodeTypeMap[item.postCode]} ${item.name}`" placement="top">
              <span>{{ roleCodeTypeMap[item.postCode] }} {{ item.name || '-' }}</span>
            </el-tooltip>
          </span>
        </div>
        <div v-if="!noAction" class="main-c action" @click="relevanceServiceDialogVisible = true">更换</div>
      </div>
    </div>
    <EditPhoneDialog
      :dialog-visible.sync="editPhoneDialogVisible"
      :user-type="userType"
      :current-data="userInfo"
      @dialogSubmit="dialogSubmit"
    />
    <RelevanceServiceDialog
      :dialog-visible.sync="relevanceServiceDialogVisible"
      :user-type="userType"
      :current-data="userInfo"
      @dialogSubmit="dialogSubmit"
    />
  </div>
</template>

<script>
import EditPhoneDialog from '../dialog/editPhoneDialog';
import RelevanceServiceDialog from '../dialog/relevanceServiceDialog';
import { goodsUserPasswdUpdate, transUserAccountPasswdUpdate } from '@/api/userManageMent';
import { roleCodeTypeMap } from '@/config/constant';
import { parseTime } from '@/utils';
export default {
  components: { EditPhoneDialog, RelevanceServiceDialog },
  filters: { parseTime },
  props: {
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    noAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      roleCodeTypeMap,
      accountStateMap: {
        0: '禁用', 1: '启用',
      },
      // accountState: true,
      userTypeMap: { 1: '货主端', 2: '承运端', 3: '司机端' },
      sourceTypeMap: { 1: '自主注册', 2: '平台注册' },
      editPhoneDialogVisible: false,
      relevanceServiceDialogVisible: false,
    };
  },
  methods: {
    resetPassword() {
      this.$confirm('确定重置密码吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async() => {
        try {
          const params = {
            account: this.userInfo.userPhone,
          };
          const apiMap = {
            1: goodsUserPasswdUpdate,
            2: transUserAccountPasswdUpdate,
          };
          const res = await apiMap[this.userType](params);
          if (res) {
            this.$baseMessage('重置成功', 'success');
          } else {
            this.$baseMessage('重置失败', 'error');
          }
        } catch (error) {
          console.log(error);
        }
      }).catch(() => {
      });
    },
    dialogSubmit() {
      this.$emit('userInfoDialogSubmit');
    },
    // 账号启用与禁用
    // async function beforeShowChangeManage() {
    //   const data = props.userInfo
    //   const status = +data.state
    //   const confirmResult = await ElMessageBox.confirm(
    //     `是否确定${status === 2 ? '启用' : '禁用'}此账号?`,
    //     '提示',
    //     {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning'
    //     }
    //   )
    //   return new Promise((resolve, reject) => {
    //     if (confirmResult) {
    //       const newData = JSON.parse(JSON.stringify(data))
    //       const params = {
    //         user: {
    //           name: newData.name,
    //           phone: newData.phone,
    //           remark: newData.remark,
    //           userId: newData.userId,
    //           updateUsername: newData.updateUsername,
    //           departId: data.departList ? data.departList.map((d) => d.id)[0] : '',
    //           postCode: data.postList ? data.postList.map((d) => d.postCode)[0] : ''
    //         },
    //         account: {
    //           account: newData.phone,
    //           state: +newData.state === 1 ? 2 : 1
    //         }
    //       }
    //       updateSysTemUser(params).then((res) => {
    //         if (res) {
    //           proxy.$modal.msg(`${status === 2 ? '启用' : '禁用'}成功`)
    //           getList()
    //           return resolve(confirm)
    //         }
    //       })
    //     } else {
    //       return reject(confirm)
    //     }
    //   })
    // }
    // 重置密码

  },
};

</script>
<style lang="scss" scoped>
.user-info-wrap {
  .text-info-item {
    .phone-value {
      width: 90px;
    }
    .customer-value {
      flex: 1;
      // max-width: 200px;
    }
  }
}
</style>
