import { render, staticRenderFns } from "./followMe.vue?vue&type=template&id=368f6fae&scoped=true&"
import script from "./followMe.vue?vue&type=script&lang=js&"
export * from "./followMe.vue?vue&type=script&lang=js&"
import style0 from "./followMe.vue?vue&type=style&index=0&id=368f6fae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368f6fae",
  null
  
)

export default component.exports