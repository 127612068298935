<template>
  <div class="real-name-auth-info-wrap card-padding">
    <div class="card-title flex-b-c">
      <div>实名认证信息</div>
      <!-- new checkStatus 4已认证 1待提交 2侍审核 3已驳回  -->
      <!-- ①实名认证状态为：未实名，按钮为【实名认证】1
②实名认证状态为：实名认证中，按钮置灰2
③实名认证状态为：已实名，无按钮4
④实名认证状态为：实名认证失败，按钮【重新提交】3-->
      <div v-if="newUserInfo.checkStatus">
        <el-button
          v-if="newUserInfo.checkStatus !== 3 && newUserInfo.checkStatus !== 4"
          :type="newUserInfo.checkStatus === 1?'primary':'info'"
          size="mini"
          :disabled="newUserInfo.checkStatus === 2"
          @click="openDrawer('add')"
        >实名认证</el-button>
        <!-- {{ newUserInfo.checkStatus === 1 ? '实名认证' : '' }} -->
        <el-button
          v-if="newUserInfo.checkStatus === 3"
          type="primary"
          size="mini"
          @click="openDrawer('edit')"
        >重新提交</el-button>
        <!-- <el-button
          type="primary"
          size="mini"
          :disabled="newUserInfo.checkStatus === 3"
          @click="openDrawer('add')"
        >实名认证</el-button> -->
      </div>
    </div>
    <template v-if="newUserInfo.checkStatus">
      <div class="card-content">
        <div class="text-info-item">
          <div class="label">姓名</div>
          <div class="value">{{ newUserInfo.userName || '-' }}</div>
          <!-- // 1已实名 2未实名 3审核中 4认证失败 -->
          <div :class="`${userPassFlagColorMap[newUserInfo.checkStatus]} status-btn`">
            {{ userPassFlagMap[newUserInfo.checkStatus] }}
          </div>
        </div>
        <div class="text-info-item">
          <div class="label">身份证号码</div>
          <div class="value">{{ newUserInfo.idCode || '-' }}</div>
        </div>
      </div>
      <div class="card-content-id">
        <div class="text-info-item">
          <div class="label">有效期止</div>
          <div class="value">{{ newUserInfo.idCodeExpire|parseTime("YYYY-MM-DD") }}</div>
          <!-- MM-DD HH:mm -->
        </div>
        <div class="text-info-item id-img-box">
          <div class="label">身份证正反面</div>
          <div class="value">
            <ImageUpload
              :model-value="newUserInfo.idCodePic"
              :limit="2"
              mode="preview"
            />
          </div>
        </div>
      </div>
    </template>
    <div v-else class="no-data">暂无数据</div>
    <RealNameAuthDrawer
      :drawer-visible.sync="drawerVisible"
      :drawer-type="drawerType"
      :user-type="userType"
      :user-info="newUserInfo"
      @drawerSubmit="drawerSubmit"
    />
  </div>
</template>

<script>
import RealNameAuthDrawer from '../drawer/realNameAuthDrawer';
import { parseTime } from '@/utils';
export default {
  components: { RealNameAuthDrawer },
  filters: { parseTime },
  props: {
    detailType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      userPassFlagMap: {
        // 1: '已实名',
        // 2: '未实名',
        // 3: '审核中',
        // 4: '认证失败',
        1: '未实名',
        2: '审核中',
        3: '认证失败',
        4: '已认证',
      },
      userPassFlagColorMap: {
        1: 'grey-btn-text',
        2: 'grey-btn-text',
        3: 'red-btn-text',
        4: 'green-btn-text',
      },
      newUserInfo: {},
      drawerVisible: false,
      drawerType: 'add',
      accountState: true,
      userTypeMap: { 1: '货主端', 2: '承运端', 3: '司机端' },
      sourceTypeMap: { 1: '自主注册', 2: '平台注册' },
      editPhoneDialogVisible: false,
      relevanceServiceDialogVisible: false,
    };
  },

  computed: {},

  watch: {
    userInfo(newVal) {
      this.newUserInfo = newVal;
    },
  },
  //   watch(
  //   () => props.userInfo,
  //   (newVal) => {
  //     newUserInfo.value = newVal;
  //   },
  //   { immediate: true },
  // );
  created() {},

  mounted() {},

  methods: {
    drawerSubmit() {
      this.$emit('userInfoDialogSubmit');
    },
    openDrawer(type) {
      this.drawerType = type;
      this.drawerVisible = true;
    },
  },
};

// 打开侧面抽屉

</script>
<style lang="scss" scoped>
.real-name-auth-info-wrap {
  .label {
    width: 87px !important;
  }
  .card-content-id {
    border-top: 1px solid #f3f4f4;
    padding: 20px 0;
    .id-img-box {
      width: 100%;
      align-items: flex-start !important;
    }
  }
}
</style>
