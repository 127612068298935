import { render, staticRenderFns } from "./myFollow.vue?vue&type=template&id=69e65802&scoped=true&"
import script from "./myFollow.vue?vue&type=script&lang=js&"
export * from "./myFollow.vue?vue&type=script&lang=js&"
import style0 from "./myFollow.vue?vue&type=style&index=0&id=69e65802&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69e65802",
  null
  
)

export default component.exports