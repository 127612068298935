<template>
  <el-dialog
    :visible="dialogVisible"
    title="关联客服"
    width="450px"
    append-to-body
    @open="open"
    @close="close"
  >
    <div>
      <el-form ref="formRef" :model="form" :rules="rules" label-position="right" label-width="80px">
        <el-form-item label="货客服" prop="role">
          <el-select v-model="form.goods" class="g-inp" placeholder="请选择">
            <el-option
              v-for="item in optionsGoods"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="+userType === 2" label="车客服" prop="role">
          <el-select v-model="form.car" class="g-inp" placeholder="请选择">
            <el-option
              v-for="item in optionsCar"
              :key="item.userId"
              :label="item.name"
              :value="item.userId"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { getSysTemPostUserList } from '@/api/systemUser';
import { goodsUserCustomerUpdate, transUserCustomerUpdate } from '@/api/userManageMent';
export default {
  props: {
    userType: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    currentData: {
      type: Object,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      optionsGoods: [],
      optionsCar: [],
      form: {
        goods: '',
        car: '',
      },
      rules: {
        goods: [{ required: true, message: '请选择', trigger: 'change' }],
        car: [{ required: true, message: '请选择', trigger: 'change' }],
      },
    };
  },
  methods: {
    open() {
      if (this.userType === 1) {
        this.getGoodsOptions();
      } else {
        this.getGoodsOptions();
        this.getCarOptions();
      }
    },
    close() {
      this.form = {
        goods: '',
        car: '',
      };
      this.$refs.formRef.resetFields();
      this.$emit('update:dialogVisible', false);
    },
    // 货客服
    getGoodsOptions() {
      const params = {
        userPostDTOList: [
          {
            postCode: 'PT004',
          },
        ],
      };
      getSysTemPostUserList(params).then((res) => {
        this.optionsGoods = res?.records || [];
        const newCurrentData = JSON.parse(JSON.stringify(this.currentData));
        let matchGoodsUserInfo = [];
        if (newCurrentData && newCurrentData.customerUserRelVOList) {
          matchGoodsUserInfo = newCurrentData.customerUserRelVOList.filter((d) => +d.type === 1)[0];
        }
        this.form.goods = matchGoodsUserInfo ? matchGoodsUserInfo.userId : '' || '';
      });
    },
    // 车客服
    getCarOptions() {
      const params = {
        userPostDTOList: [
          {
            postCode: 'PT005',
          },
        ],
      };
      getSysTemPostUserList(params).then((res) => {
        this.optionsCar = res?.records || [];
        const newCurrentData = JSON.parse(JSON.stringify(this.currentData));
        let matchCarUserInfo = [];
        if (newCurrentData && newCurrentData.customerUserRelVOList) {
          matchCarUserInfo = newCurrentData.customerUserRelVOList.filter((d) => +d.type === 2)[0];
        }
        this.form.car = matchCarUserInfo ? matchCarUserInfo.userId : '' || '';
      });
    },
    submit() {
      this.$refs.formRef.validate(async(valid) => {
        if (valid) {
          try {
            /**
             * customerUserId 货主userId
             * type 1货客服 车客服
             * userId 客服人员userId
             * ext3 客服人员姓名
             */
            const selectGoodsInfo = this.optionsGoods.filter((d) => +d.userId === +this.form.goods)[0] || {};
            console.log(selectGoodsInfo, 'sdadd');
            const selectCarInfo = this.optionsCar.filter((d) => +d.userId === +this.form.car)[0] || {};
            const paramsGoods = {
              customerUserId: this.currentData.userId,
              type: 1,
              userId: selectGoodsInfo.userId,
              ext3: selectGoodsInfo.name,
              postCode: 'PT004',
            };
            const paramsCar = +this.userType === 2 && {
              customerUserId: this.currentData.userId,
              type: 2,
              userId: selectCarInfo.userId,
              ext3: selectCarInfo.name,
              postCode: 'PT005',
            };
            const params = +this.userType === 1 ? [paramsGoods] : [paramsGoods, paramsCar];
            const apiMap = {
              1: goodsUserCustomerUpdate,
              2: transUserCustomerUpdate,
            };
            const res = await apiMap[this.userType](params);
            if (res) {
              this.$baseMessage('关联客服成功', 'success');
              this.$emit('dialogSubmit');
              this.close();
            } else {
              this.$baseMessage('关联客服失败', 'error');
            }
          } catch (error) {
            console.error('失败:', error);
          }
        }
      });
    },
  },
};

</script>

<style lang="scss" scoped></style>
